import moment from 'moment'

const timeFrom = (eventDate) => {
  const sec_num = moment(eventDate).diff(moment(), 'seconds')

  const days = Math.floor(sec_num / 3600 / 24)
  const hh = Math.floor(sec_num / 3600) % 24
  const mm = Math.floor(sec_num / 60) % 60
  const ss = sec_num % 60
  let res = ''
  if (days.toFixed().length === 1) res += '0'
  res += `${days}:`

  if (hh.toFixed().length === 1) res += '0'
  res += `${hh}:`

  if (mm.toFixed().length === 1) res += '0'
  res += `${mm}:`

  if (ss.toFixed().length === 1) res += '0'
  res += `${ss}`

  return res
}

const secondsTo = (date) => {
  return moment(date).diff(moment(), 'seconds')
}

export const timeHelper = {
  timeFrom,
  secondsTo
}
